@use "variables" as var;
#root {
    background-color: var.$background_color;
    a {
        text-decoration: none;
    }
    .bold {
        font-weight: bold;
    }
}

#content {
    padding-top: var.$header_size + 100px;
}

@media screen and (max-width: 640px) {
    #content {
        padding-top: var.$header_size + 30px;
    }
}