@use "variables" as var;
header {
    background-color: var.$dark_background;
    height: var.$header_size;
    display: grid;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    #header-name-container {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: var.$header_size 1fr;
        figure {
            margin: 0;
            height: var.$header_size;
        }
        .name {
            height: var.$header_size;
            line-height: var.$header_size;
            color: white;
            font-weight: bold;
        }
        .header-logo {
            box-sizing: border-box;
            padding: 10px;
        }
    }
    margin-bottom: 50px;
}