@use "variables" as var;
.login-wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 400px;
    max-width: 80%;
    form {
        background-color: var.$dark_background;
        color: white;
        padding: 50px;
        border-radius: 20px;
        label {
            display: block;
            input {
                width: 100%;
                box-sizing: border-box;
            }
        }
        .submit-button {
            padding: 30px 0 20px;
            text-align: center;
            button {
                padding: 10px 20px;
                border-radius: 20px;
                cursor: pointer;
                transition: .4s ease-in-out;
                &:hover {
                    opacity: .75;
                    color: white;
                    background-color: black;
                }
            }
        }
        .message {
            text-align: center;
        }
    }
}

@media screen and (max-width: 1024px) {
    .login-wrapper {
        form {
            padding: 45px;
        }
    }
}

@media screen and (max-width: 834px) {
    .login-wrapper {
        form {
            padding: 40px;
        }
    }
}

@media screen and (max-width: 480px) {
    .login-wrapper {
        form {
            padding: 20px;
        }
    }
}