@use "variables" as var;
#intro-wrapper {
    display: grid;
    grid-template-columns: var.$grid_wrapper;
    &>* {
        grid-column: 5 / -5;
    }
    #introduction {
        display: grid;
        grid-template-columns: var.$column_grid_two;
        align-items: center;
        margin-bottom: 100px;
        column-gap: 10px;
        #model {
            color: white;
            font-weight: bold;
            height: 300px;
            background-color: black;
            .model-loading {
                text-align: center;
                line-height: 300px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #intro-wrapper {
        &>* {
            grid-column: 4 / -4;
        }
    }
}

@media screen and (max-width: 640px) {
    #intro-wrapper {
        #introduction {
            row-gap: 50px;
            &>* {
                grid-column: 1 / -1;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    #intro-wrapper {
        &>* {
            grid-column: 2 / -2;
        }
    }
}