@use "variables" as var;
#content.app-page {
    padding-top: 100px;
    h1 {
        text-align: center;
        padding: 0 20px;
    }
    figure {
        margin: 0;
        margin-bottom: 80px;
        padding: 0;
        text-align: center;
        img {
            width: auto;
            height: 400px;
            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
        }
    }
    a {
        color: var.$link-color;
        transition: color 1s;
        position: relative;
        &:hover {
            text-decoration: none;
            color: var.$link-hover-color;
            &::after {
                width: 100%;
            }
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 2px;
            background-color: #31aae2;
            transition: .3s;
            transform: translateX(-50%);
        }
    }
    .description-wrapper {
        padding: 50px 0;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: var.$grid_wrapper;
        background-color: black;
        color: white;
        &>* {
            grid-column: 5 / -5;
        }
        .description {
            display: grid;
            grid-template-columns: 2fr 1fr;
            column-gap: 40px;
            h2:nth-of-type(1) {
                margin-top: 0;
            }
            .detail {
                p:nth-of-type(1) {
                    margin-top: 0;
                }
            }
        }
    }
    .image-wrapper {
        padding: 50px 0;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: var.$grid_wrapper;
        background-color: black;
        color: white;
        &>* {
            grid-column: 5 / -5;
        }
        .images-pdfs {
            display: grid;
            grid-template-columns: var.$column_grid_three;
            column-gap: 50px;
            row-gap: 50px;
            figure {
                margin: 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .more {
            margin-top: 50px;
            iframe {
                display: block;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
    ul.no-point {
        position: relative;
        padding: 0;
        margin: auto;
        li {
            list-style-type: none;
            padding: 0.5em 0.5em 0.5em 2.0em;
            font-size: 1rem;
        }
        .fa-icon {
            position: relative;
            padding-right: 5px;
            top: 2px;
        }
        .rotate {
            transform: rotate(180deg);
            padding-right: 0;
            padding-left: 5px;
        }
        .invert {
            filter: invert(1);
        }
    }
}

@media screen and (max-width: 1024px) {
    #content.app-page {
        .description-wrapper {
            &>* {
                grid-column: 4 / -4;
            }
        }
        .image-wrapper {
            &>* {
                grid-column: 4 / -4;
            }
            .images-pdfs {
                grid-template-columns: var.$column_grid_two;
            }
        }
    }
}

@media screen and (max-width: 834px) {
    #content.app-page {
        figure {
            img {
                height: auto;
                max-width: 90%;
            }
        }
        .description-wrapper {
            .description {
                row-gap: 50px;
                &>* {
                    grid-column: 1 / -1;
                }
            }
        }
        .image-wrapper {
            .images-pdfs {
                &>* {
                    grid-column: 1 / -1;
                }
            }
            .more {
                iframe {
                    max-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    #content.app-page {
        h1 {
            font-size: 1.6em;
        }
        .description-wrapper {
            &>* {
                grid-column: 3 / -3;
            }
            .description {
                &>* {
                    grid-column: 1 / -1;
                }
            }
        }
        .image-wrapper {
            &>* {
                grid-column: 3 / -3;
            }
            .more {
                iframe {
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    #content.app-page {
        figure {
            img {
                max-width: 95%;
            }
        }
        .description-wrapper {
            &>* {
                grid-column: 2 / -2;
            }
        }
        .image-wrapper {
            &>* {
                grid-column: 2 / -2;
            }
        }
    }
}