@use "variables" as var;
#publication {
    padding-bottom: 100px;
    .heading {
        text-align: center;
        padding: 40px;
        font-size: 3em;
    }
    ul.paper-list {
        position: relative;
        padding: 0;
        margin: auto;
        li {
            list-style-type: none;
            padding: 0.5em 0.5em 0.5em 2.0em;
            font-weight: normal;
            font-size: 1rem;
        }
        &>li {
            font-weight: bold;
            font-size: 1.2em;
        }
        .paper-icon {
            position: relative;
            padding-right: 5px;
            top: 2px;
        }
    }
    .publication-images {
        figure img {
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    #publication {
        .heading {
            font-size: 2em;
        }
        ul.paper-list {
            li {
                padding-left: 1em;
                ul {
                    padding: 0;
                }
            }
        }
    }
}