$dark_background: #282c34;
$header_size: 70px;
$background_color: #c2c2c2;
$link-color: #dba200;
$link-hover-color: #33c6ff;
$body_column_grid: 20px 20px 1fr 1fr 20px 20px;
$grid_wrapper: 20px 20px 20px 20px 1fr 20px 20px 20px 20px;
$column_grid_three: 1fr 1fr 1fr;
$column_grid_two: 1fr 1fr;
@mixin application($wrapper, $bgColor, $color, $app_bgColor, $application_shadow, $hover_shadow, $hover_color) {
    ##{$wrapper} {
        display: grid;
        grid-template-columns: $grid_wrapper;
        grid-template-rows: auto auto;
        background-color: $bgColor;
        padding-bottom: 100px;
        padding-top: 30px;
        color: $color;
        &>* {
            grid-column: 5 / -5;
        }
        a {
            color: $color;
        }
        .heading {
            text-align: center;
            padding: 40px;
            font-size: 3em;
            grid-row: 1/ 2;
        }
        .application-list {
            display: grid;
            grid-template-columns: $column_grid_three;
            column-gap: 50px;
            row-gap: 50px;
            .application {
                box-shadow: $application_shadow;
                border-radius: 3px;
                transition: .2s ease-in-out;
                background-color: $app_bgColor;
                position: relative;
                cursor: pointer;
                overflow: hidden;
                &:hover {
                    box-shadow: $hover_shadow;
                    transform: translateY(-4px);
                    background-color: $color;
                    color: $hover_color;
                }
                .language {
                    display: inline-block;
                    position: absolute;
                    padding: 5px 0;
                    right: -47px;
                    top: 20px;
                    width: 160px;
                    font-weight: bold;
                    font-size: .8em;
                    text-align: center;
                    line-height: .8em;
                    background-color: #ffce80;
                    color: black;
                    transform: rotate(45deg);
                    box-shadow: 0 0 0 2px rgba(0 0 0 / 10%);
                }
                figure {
                    margin: 0;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .description {
                    transition: .2s ease-in-out;
                    padding: 0 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ##{$wrapper} {
            &>* {
                grid-column: 4 / -4;
            }
        }
    }
    @media screen and (max-width: 834px) {
        ##{$wrapper} {
            .application-list {
                grid-template-columns: $column_grid_two;
            }
        }
    }
    @media screen and (max-width: 480px) {
        ##{$wrapper} {
            &>* {
                grid-column: 2 / -2;
            }
            .heading {
                font-size: 2em;
            }
            .application-list {
                &>* {
                    grid-column: 1 / -1;
                }
            }
        }
    }
}